.butter {
  background: transparent url('/images/butter.png') no-repeat center;
  background-size: cover;
}

.butter-biscuit {
  background: transparent url('/images/biscuit.png') no-repeat center;
  background-size: cover;
}

.bread {
  background: transparent url('/images/bread.png') no-repeat center;
  background-size: cover;
}

.cereal {
  background: transparent url('/images/cereal.png') no-repeat center;
  background-size: cover;
}

.checked {
  background: transparent url('/images/checked.png') no-repeat center;
  background-size: cover;
}

.cheese {
  background: transparent url('/images/cheese.png') no-repeat center;
  background-size: cover;
}

.chestnut {
  background: transparent url('/images/chestnut.png') no-repeat center;
  background-size: cover;
}

.cinnamon {
  background: transparent url('/images/cinnamon.png') no-repeat center;
  background-size: cover;
}

.coconut-milk-yoghurt {
  background: transparent url('/images/yogurt.png') no-repeat center;
  background-size: cover;
}

.cream {
  background: transparent url('/images/cream.png') no-repeat center;
  background-size: cover;
}

.flour {
  background: transparent url('/images/flour.png') no-repeat center;
  background-size: cover;
}

.goat-cheese {
  background: transparent url('/images/cheese.png') no-repeat center;
  background-size: cover;
}

.goat-milk-yoghurt {
  background: transparent url('/images/yogurt.png') no-repeat center;
  background-size: cover;
}

.hazelnut-oil {
  background: transparent url('/images/oil.png') no-repeat center;
  background-size: cover;
}

.honey {
  background: transparent url('/images/honey.png') no-repeat center;
  background-size: cover;
}

.kiri {
  background: transparent url('/images/cheese.png') no-repeat center;
  background-size: cover;
}

.ladyfinger {
  background: transparent url('/images/biscuit.png') no-repeat center;
  background-size: cover;
}

.olive-oil {
  background: transparent url('/images/olive-oil.png') no-repeat center;
  background-size: cover;
}

.pasta {
  background: transparent url('/images/pasta.png') no-repeat center;
  background-size: cover;
}

.rapeseed-oil {
  background: transparent url('/images/oil.png') no-repeat center;
  background-size: cover;
}

.rice {
  background: transparent url('/images/rice.png') no-repeat center;
  background-size: cover;
}

.rolled-oats {
  background: transparent url('/images/rolled-oats.png') no-repeat center;
  background-size: cover;
}

.rosemary {
  background: transparent url('/images/rosemary.png') no-repeat center;
  background-size: cover;
}

.salt {
  background: transparent url('/images/salt.png') no-repeat center;
  background-size: cover;
}

.semolina {
  background: transparent url('/images/semolina.png') no-repeat center;
  background-size: cover;
}

.sheep-cheese {
  background: transparent url('/images/cheese.png') no-repeat center;
  background-size: cover;
}

.sheep-milk-yoghurt {
  background: transparent url('/images/yogurt.png') no-repeat center;
  background-size: cover;
}

.spelt {
  background: transparent url('/images/spelt.png') no-repeat center;
  background-size: cover;
}

.sugar {
  background: transparent url('/images/sugar.png') no-repeat center;
  background-size: cover;
}

.sunflower-oil {
  background: transparent url('/images/oil.png') no-repeat center;
  background-size: cover;
}

.thyme {
  background: transparent url('/images/thyme-1.png') no-repeat center;
  background-size: cover;
}

.vanilla {
  background: transparent url('/images/vanilla.png') no-repeat center;
  background-size: cover;
}

.vache-qui-rit {
    background: transparent url('/images/cheese.png') no-repeat center;
    background-size: cover;
}

.verveina {
  background: transparent url('/images/verveina.png') no-repeat center;
  background-size: cover;
}

.yogurt {
  background: transparent url('/images/yogurt.png') no-repeat center;
  background-size: cover;
}
