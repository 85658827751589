.MuiButton-containedPrimary {
  color: var(--color-font) !important;
}

.MuiButton-textPrimary {
  color: var(--color-primary-dark) !important;
}
.MuiButton-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}
.MuiAppBar-colorPrimary {
  background: white !important;
  color: var(--color-font) !important;
  border-bottom: 1px solid var(--color-primary);
}
