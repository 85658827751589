.PleasureRating .MuiRating-iconFilled.very-dissatisfied {
  color: red
}
.PleasureRating .MuiRating-iconFilled.dissatisfied {
  color: orangered;
}
.PleasureRating .MuiRating-iconFilled.neutral {
  color: orange;
}
.PleasureRating .MuiRating-iconFilled.satisfied {
  color: mediumseagreen;
}
.PleasureRating .MuiRating-iconFilled.very-satisfied {
  color: green;
}

.PleasureRating {
  padding: 5px 0;
}
