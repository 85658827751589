.add-meal__create-meal-button {
  position: absolute !important;
  bottom: 1em;
  right: 1em;
}

.add-meal__create-day-meals-button {
  position: absolute !important;
  bottom: 1em;
  right: 8em;
}
