.information-modal-line__col {
  display: inherit;
}

.information-modal-line__row {
  display: none;
}
@media (max-width: 520px) {
  .information-modal-line__col {
    display: none;
  }

  .information-modal-line__row {
    display: inherit;
  }
}
