/* margin */
.m-n {
  margin: 0;
}

.m-l {
  margin: var(--margin-l);
}

.m-m {
  margin: var(--margin-m);
}

.m-s {
  margin: var(--margin-s);
}

.m-xs {
  margin: var(--margin-xs);
}


/* margin right */
.mr-l {
  margin-right: var(--margin-l);
}

.mr-m {
  margin-right: var(--margin-m);
}

.mr-s {
  margin-right: var(--margin-s);
}

.mr-xs {
  margin-right: var(--margin-xs);
}

.mr-n {
  margin-right: 0;
}

/* margin left */
.ml-l {
  margin-left: var(--margin-l);
}

.ml-m {
  margin-left: var(--margin-m);
}

.ml-s {
  margin-left: var(--margin-s);
}

.ml-xs {
  margin-left: var(--margin-xs);
}

.ml-n {
  margin-left: 0;
}

/* margin bottom */
.mb-l {
  margin-bottom: var(--margin-l);
}

.mb-m {
  margin-bottom: var(--margin-m);
}

.mb-s {
  margin-bottom: var(--margin-s);
}

.mb-xs {
  margin-bottom: var(--margin-xs);
}

.mb-n {
  margin-bottom: 0;
}

/* margin top */
.mt-l {
  margin-top: var(--margin-l);
}

.mt-m {
  margin-top: var(--margin-m);
}

.mt-s {
  margin-top: var(--margin-s);
}

.mt-xs {
  margin-top: var(--margin-xs);
}

.mt-n {
  margin-top: 0;
}

/* padding */
.p-n {
  padding: 0;
}

.p-l {
  padding: var(--margin-l);
}

.p-m {
  padding: var(--margin-m);
}

.p-s {
  padding: var(--margin-s);
}

.p-xs {
  padding: var(--margin-xs);
}

/* padding right */
.pr-l {
  padding-right: var(--margin-l);
}

.pr-m {
  padding-right: var(--margin-m);
}

.pr-s {
  padding-right: var(--margin-s);
}

.pr-xs {
  padding-right: var(--margin-xs);
}

.pr-n {
  padding-right: 0;
}

/* padding left */
.pl-l {
  padding-left: var(--margin-l);
}

.pl-m {
  padding-left: var(--margin-m);
}

.pl-s {
  padding-left: var(--margin-s);
}

.pl-xs {
  padding-left: var(--margin-xs);
}

.pl-n {
  padding-left: 0;
}

/* padding bottom */
.pb-l {
  padding-bottom: var(--margin-l);
}

.pb-m {
  padding-bottom: var(--margin-m);
}

.pb-s {
  padding-bottom: var(--margin-s);
}

.pb-xs {
  padding-bottom: var(--margin-xs);
}

.pb-n {
  padding-bottom: 0;
}

/* padding top */
.pt-l {
  padding-top: var(--margin-l);
}

.pt-m {
  padding-top: var(--margin-m);
}

.pt-s {
  padding-top: var(--margin-s);
}

.pt-xs {
  padding-top: var(--margin-xs);
}

.pt-n {
  padding-top: 0;
}
