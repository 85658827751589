@import url(https://fonts.googleapis.com/css?family=Roboto:100,200,400,700,900);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
:root {
  --color-font: #233140 !important;
  --color-background: white;
  --color-primary: #7ed739;
  --color-primary-dark: #71b323;
  --color-error: #a30200;
  --margin-xs: 0.25em;
  --margin-s: 0.5em;
  --margin-m: 1em;
  --margin-l: 1.5em;
}

/* margin */
.m-n {
  margin: 0;
}

.m-l {
  margin: var(--margin-l);
}

.m-m {
  margin: var(--margin-m);
}

.m-s {
  margin: var(--margin-s);
}

.m-xs {
  margin: var(--margin-xs);
}


/* margin right */
.mr-l {
  margin-right: var(--margin-l);
}

.mr-m {
  margin-right: var(--margin-m);
}

.mr-s {
  margin-right: var(--margin-s);
}

.mr-xs {
  margin-right: var(--margin-xs);
}

.mr-n {
  margin-right: 0;
}

/* margin left */
.ml-l {
  margin-left: var(--margin-l);
}

.ml-m {
  margin-left: var(--margin-m);
}

.ml-s {
  margin-left: var(--margin-s);
}

.ml-xs {
  margin-left: var(--margin-xs);
}

.ml-n {
  margin-left: 0;
}

/* margin bottom */
.mb-l {
  margin-bottom: var(--margin-l);
}

.mb-m {
  margin-bottom: var(--margin-m);
}

.mb-s {
  margin-bottom: var(--margin-s);
}

.mb-xs {
  margin-bottom: var(--margin-xs);
}

.mb-n {
  margin-bottom: 0;
}

/* margin top */
.mt-l {
  margin-top: var(--margin-l);
}

.mt-m {
  margin-top: var(--margin-m);
}

.mt-s {
  margin-top: var(--margin-s);
}

.mt-xs {
  margin-top: var(--margin-xs);
}

.mt-n {
  margin-top: 0;
}

/* padding */
.p-n {
  padding: 0;
}

.p-l {
  padding: var(--margin-l);
}

.p-m {
  padding: var(--margin-m);
}

.p-s {
  padding: var(--margin-s);
}

.p-xs {
  padding: var(--margin-xs);
}

/* padding right */
.pr-l {
  padding-right: var(--margin-l);
}

.pr-m {
  padding-right: var(--margin-m);
}

.pr-s {
  padding-right: var(--margin-s);
}

.pr-xs {
  padding-right: var(--margin-xs);
}

.pr-n {
  padding-right: 0;
}

/* padding left */
.pl-l {
  padding-left: var(--margin-l);
}

.pl-m {
  padding-left: var(--margin-m);
}

.pl-s {
  padding-left: var(--margin-s);
}

.pl-xs {
  padding-left: var(--margin-xs);
}

.pl-n {
  padding-left: 0;
}

/* padding bottom */
.pb-l {
  padding-bottom: var(--margin-l);
}

.pb-m {
  padding-bottom: var(--margin-m);
}

.pb-s {
  padding-bottom: var(--margin-s);
}

.pb-xs {
  padding-bottom: var(--margin-xs);
}

.pb-n {
  padding-bottom: 0;
}

/* padding top */
.pt-l {
  padding-top: var(--margin-l);
}

.pt-m {
  padding-top: var(--margin-m);
}

.pt-s {
  padding-top: var(--margin-s);
}

.pt-xs {
  padding-top: var(--margin-xs);
}

.pt-n {
  padding-top: 0;
}

.MuiButton-containedPrimary {
  color: var(--color-font) !important;
}

.MuiButton-textPrimary {
  color: var(--color-primary-dark) !important;
}
.MuiButton-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}
.MuiAppBar-colorPrimary {
  background: white !important;
  color: var(--color-font) !important;
  border-bottom: 1px solid var(--color-primary);
}

.apple {
  background: transparent url('/images/apple.png') no-repeat center;
  background-size: cover;
}

.apricot {
  background: transparent url('/images/apricot.png') no-repeat center;
  background-size: cover;
}

.banana {
  background: transparent url('/images/banana.png') no-repeat center;
  background-size: cover;
}

.blackberry {
  background: transparent url('/images/blackberry.png') no-repeat center;
  background-size: cover;
}

.blueberry {
  background: transparent url('/images/blueberry.png') no-repeat center;
  background-size: cover;
}

.brugnon {
  background: transparent url('/images/peach.png') no-repeat center;
  background-size: cover;
}

.cherry {
  background: transparent url('/images/cherry.png') no-repeat center;
  background-size: cover;
}

.citrus {
  background: transparent url('/images/citrus.png') no-repeat center;
  background-size: cover;
}

.clementine {
  background: transparent url('/images/clementine.png') no-repeat center;
  background-size: cover;
}

.coconut {
  background: transparent url('/images/coconut.png') no-repeat center;
  background-size: cover;
}

.fig {
  background: transparent url('/images/fig.png') no-repeat center;
  background-size: cover;
}

.gooseberry {
  background: transparent url('/images/gooseberry.png') no-repeat center;
  background-size: cover;
}

.guava {
  background: transparent url('/images/guava.png') no-repeat center;
  background-size: cover;
}

.grapefruit {
  background: transparent url('/images/grapefruit.png') no-repeat center;
  background-size: cover;
}

.grapes {
  background: transparent url('/images/grapes.png') no-repeat center;
  background-size: cover;
}

.kiwi {
  background: transparent url('/images/kiwi.png') no-repeat center;
  background-size: cover;
}

.lime {
  background: transparent url('/images/lime.png') no-repeat center;
  background-size: cover;
}

.mango {
  background: transparent url('/images/mango.png') no-repeat center;
  background-size: cover;
}

.melon {
  background: transparent url('/images/melon.png') no-repeat center;
  background-size: cover;
}

.mirabelle-plum {
  background: transparent url('/images/plum.png') no-repeat center;
  background-size: cover;
}

.nectarine {
  background: transparent url('/images/peach.png') no-repeat center;
  background-size: cover;
}

.orange {
  background: transparent url('/images/orange.png') no-repeat center;
  background-size: cover;
}

.passion-fruit {
  background: transparent url('/images/passion-fruit.png') no-repeat center;
  background-size: cover;
}

.peach {
  background: transparent url('/images/peach.png') no-repeat center;
  background-size: cover;
}

.pear {
  background: transparent url('/images/pear.png') no-repeat center;
  background-size: cover;
}

.persimmon {
  background: transparent url('/images/persimmon.png') no-repeat center;
  background-size: cover;
}

.pineapple {
  background: transparent url('/images/pineapple.png') no-repeat center;
  background-size: cover;
}

.plum {
  background: transparent url('/images/plum.png') no-repeat center;
  background-size: cover;
}

.prune {
  background: transparent url('/images/plum.png') no-repeat center;
  background-size: cover;
}

.quince {
  background: transparent url('/images/quince.png') no-repeat center;
  background-size: cover;
}

.raspberry {
  background: transparent url('/images/raspberry.png') no-repeat center;
  background-size: cover;
}

.rhubarb {
  background: transparent url('/images/rhubarb.png') no-repeat center;
  background-size: cover;
}

.strawberry {
  background: transparent url('/images/strawberry.png') no-repeat center;
  background-size: cover;
}

.tangerine {
  background: transparent url('/images/tangerine.png') no-repeat center;
  background-size: cover;
}

.watermelon {
  background: transparent url('/images/watermelon.png') no-repeat center;
  background-size: cover;
}

.artichoke {
  background: transparent url('/images/artichoke.png') no-repeat center;
  background-size: cover;
}

.asparagus {
  background: transparent url('/images/asparagus.png') no-repeat center;
  background-size: cover;
}

.avocado {
  background: transparent url('/images/avocado.png') no-repeat center;
  background-size: cover;
}

.beet {
  background: transparent url('/images/beet.png') no-repeat center;
  background-size: cover;
}

.bell-pepper {
  background: transparent url('/images/bell-pepper.png') no-repeat center;
  background-size: cover;
}

.broccoli {
  background: transparent url('/images/broccoli.png') no-repeat center;
  background-size: cover;
}

.brussels-sprout {
  background: transparent url('/images/brussels-sprout.png') no-repeat center;
  background-size: cover;
}

.cabbage {
  background: transparent url('/images/cabbage.png') no-repeat center;
  background-size: cover;
}

.carrot {
  background: transparent url('/images/carrot.png') no-repeat center;
  background-size: cover;
}

.cauliflower {
  background: transparent url('/images/cauliflower.png') no-repeat center;
  background-size: cover;
}

.celery {
  background: transparent url('/images/celery.png') no-repeat center;
  background-size: cover;
}

.chard {
  background: transparent url('/images/chard.png') no-repeat center;
  background-size: cover;
}

.corn {
  background: transparent url('/images/corn.png') no-repeat center;
  background-size: cover;
}

.coconut-milk {
  background: transparent url('/images/coconut-milk.png') no-repeat center;
  background-size: cover;
}

.cucumber {
  background: transparent url('/images/cucumber.png') no-repeat center;
  background-size: cover;
}

.eggplant {
  background: transparent url('/images/eggplant.png') no-repeat center;
  background-size: cover;
}

.fennel {
  background: transparent url('/images/fennel.png') no-repeat center;
  background-size: cover;
}

.garlic {
  background: transparent url('/images/garlic.png') no-repeat center;
  background-size: cover;
}

.gourd {
  background: transparent url('/images/gourd.png') no-repeat center;
  background-size: cover;
}

.leek {
  background: transparent url('/images/leek.png') no-repeat center;
  background-size: cover;
}

.mix-vegetable {
  background: transparent url('/images/mix-vegetable.png') no-repeat center;
  background-size: cover;
}

.mushroom {
  background: transparent url('/images/mushroom.png') no-repeat center;
  background-size: cover;
}

.olive {
  background: transparent url('/images/olive.png') no-repeat center;
  background-size: cover;
}

.onion {
  background: transparent url('/images/onion.png') no-repeat center;
  background-size: cover;
}

.parsnip {
  background: transparent url('/images/parsnip.png') no-repeat center;
  background-size: cover;
}

.peas {
  background: transparent url('/images/peas.png') no-repeat center;
  background-size: cover;
}

.potato {
  background: transparent url('/images/potato.png') no-repeat center;
  background-size: cover;
}

.pumpkin {
  background: transparent url('/images/pumpkin.png') no-repeat center;
  background-size: cover;
}

.radish {
  background: transparent url('/images/radish.png') no-repeat center;
  background-size: cover;
}

.soy {
  background: transparent url('/images/soy.png') no-repeat center;
  background-size: cover;
}

.spinach {
  background: transparent url('/images/spinach.png') no-repeat center;
  background-size: cover;
}

.squash {
  background: transparent url('/images/squash.png') no-repeat center;
  background-size: cover;
}

.sweet-potato {
  background: transparent url('/images/sweet-potato.png') no-repeat center;
  background-size: cover;
}

.tomato {
  background: transparent url('/images/tomato.png') no-repeat center;
  background-size: cover;
}

.turnip {
  background: transparent url('/images/turnip.png') no-repeat center;
  background-size: cover;
}

.white-beans {
  background: transparent url('/images/white-beans.png') no-repeat center;
  background-size: cover;
}

.zucchini {
  background: transparent url('/images/zucchini.png') no-repeat center;
  background-size: cover;
}

.beef {
  background: transparent url('/images/beef.png') no-repeat center;
  background-size: cover;
}

.chicken {
  background: transparent url('/images/chicken.png') no-repeat center;
  background-size: cover;
}

.chicken-breast {
  background: transparent url('/images/chicken-breast.png') no-repeat center;
  background-size: cover;
}

.chopped-steak {
  background: transparent url('/images/chopped-steak.png') no-repeat center;
  background-size: cover;
}

.egg {
  background: transparent url('/images/egg.png') no-repeat center;
  background-size: cover;
}

.fish-food {
  background: transparent url('/images/fish-food.png') no-repeat center;
  background-size: cover;
}

.ham {
  background: transparent url('/images/ham.png') no-repeat center;
  background-size: cover;
}

.lamb {
  background: transparent url('/images/sheep.png') no-repeat center;
  background-size: cover;
}

.mutton {
  background: transparent url('/images/sheep.png') no-repeat center;
  background-size: cover;
}

.pig {
  background: transparent url('/images/pig.png') no-repeat center;
  background-size: cover;
}

.shrimp {
  background: transparent url('/images/shrimp.png') no-repeat center;
  background-size: cover;
}

.turkey {
  background: transparent url('/images/turkey.png') no-repeat center;
  background-size: cover;
}

.turkey-breast {
  background: transparent url('/images/chicken-breast.png') no-repeat center;
  background-size: cover;
}

.veal {
  background: transparent url('/images/beef.png') no-repeat center;
  background-size: cover;
}

.butter {
  background: transparent url('/images/butter.png') no-repeat center;
  background-size: cover;
}

.butter-biscuit {
  background: transparent url('/images/biscuit.png') no-repeat center;
  background-size: cover;
}

.bread {
  background: transparent url('/images/bread.png') no-repeat center;
  background-size: cover;
}

.cereal {
  background: transparent url('/images/cereal.png') no-repeat center;
  background-size: cover;
}

.checked {
  background: transparent url('/images/checked.png') no-repeat center;
  background-size: cover;
}

.cheese {
  background: transparent url('/images/cheese.png') no-repeat center;
  background-size: cover;
}

.chestnut {
  background: transparent url('/images/chestnut.png') no-repeat center;
  background-size: cover;
}

.cinnamon {
  background: transparent url('/images/cinnamon.png') no-repeat center;
  background-size: cover;
}

.coconut-milk-yoghurt {
  background: transparent url('/images/yogurt.png') no-repeat center;
  background-size: cover;
}

.cream {
  background: transparent url('/images/cream.png') no-repeat center;
  background-size: cover;
}

.flour {
  background: transparent url('/images/flour.png') no-repeat center;
  background-size: cover;
}

.goat-cheese {
  background: transparent url('/images/cheese.png') no-repeat center;
  background-size: cover;
}

.goat-milk-yoghurt {
  background: transparent url('/images/yogurt.png') no-repeat center;
  background-size: cover;
}

.hazelnut-oil {
  background: transparent url('/images/oil.png') no-repeat center;
  background-size: cover;
}

.honey {
  background: transparent url('/images/honey.png') no-repeat center;
  background-size: cover;
}

.kiri {
  background: transparent url('/images/cheese.png') no-repeat center;
  background-size: cover;
}

.ladyfinger {
  background: transparent url('/images/biscuit.png') no-repeat center;
  background-size: cover;
}

.olive-oil {
  background: transparent url('/images/olive-oil.png') no-repeat center;
  background-size: cover;
}

.pasta {
  background: transparent url('/images/pasta.png') no-repeat center;
  background-size: cover;
}

.rapeseed-oil {
  background: transparent url('/images/oil.png') no-repeat center;
  background-size: cover;
}

.rice {
  background: transparent url('/images/rice.png') no-repeat center;
  background-size: cover;
}

.rolled-oats {
  background: transparent url('/images/rolled-oats.png') no-repeat center;
  background-size: cover;
}

.rosemary {
  background: transparent url('/images/rosemary.png') no-repeat center;
  background-size: cover;
}

.salt {
  background: transparent url('/images/salt.png') no-repeat center;
  background-size: cover;
}

.semolina {
  background: transparent url('/images/semolina.png') no-repeat center;
  background-size: cover;
}

.sheep-cheese {
  background: transparent url('/images/cheese.png') no-repeat center;
  background-size: cover;
}

.sheep-milk-yoghurt {
  background: transparent url('/images/yogurt.png') no-repeat center;
  background-size: cover;
}

.spelt {
  background: transparent url('/images/spelt.png') no-repeat center;
  background-size: cover;
}

.sugar {
  background: transparent url('/images/sugar.png') no-repeat center;
  background-size: cover;
}

.sunflower-oil {
  background: transparent url('/images/oil.png') no-repeat center;
  background-size: cover;
}

.thyme {
  background: transparent url('/images/thyme-1.png') no-repeat center;
  background-size: cover;
}

.vanilla {
  background: transparent url('/images/vanilla.png') no-repeat center;
  background-size: cover;
}

.vache-qui-rit {
    background: transparent url('/images/cheese.png') no-repeat center;
    background-size: cover;
}

.verveina {
  background: transparent url('/images/verveina.png') no-repeat center;
  background-size: cover;
}

.yogurt {
  background: transparent url('/images/yogurt.png') no-repeat center;
  background-size: cover;
}

.icon-sm {
  width: 25px;
  height: 25px;
}

.icon-xs {
  width: 15px;
  height: 15px;
}

.icon-md {
  width: 35px;
  height: 35px;
}

.icon-lg {
  width: 45px;
  height: 45px;
}

.breakfast {
    background: transparent url('/images/breakfast.png') no-repeat center;
    background-size: cover;
}

.teatime {
    background: transparent url('/images/teatime.png') no-repeat center;
    background-size: cover;
}

.dairy {
    background: transparent url('/images/dairy.png') no-repeat center;
    background-size: cover;
}

.dinner {
    background: transparent url('/images/dinner.png') no-repeat center;
    background-size: cover;
}

.lunch {
    background: transparent url('/images/lunch.png') no-repeat center;
    background-size: cover;
}

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-font);
  background: var(--color-background);
  outline: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

a {
  color: var(--color-font);
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1 1;
}

.row {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.col {
  display: flex;
  flex-flow: column;
}

.wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between !important;
}

.space-evenly {
  justify-content: space-evenly;
}

.space-around {
  justify-content: space-around;
}

.flex-end {
  justify-content: flex-end;
}

.clickable {
  cursor: pointer;
}

.center {
  text-align: center;
}

.justify {
  text-align: justify;
}

.full {
  width: 100%;
}

.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.scrollable-container {
  flex: 1 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  justify-content: center;
  outline: none;
}

.container {
  height: 100%;
  width: 98%;
  justify-content: center;
  margin: auto;
}

.PleasureRating .MuiRating-iconFilled.very-dissatisfied {
  color: red
}
.PleasureRating .MuiRating-iconFilled.dissatisfied {
  color: orangered;
}
.PleasureRating .MuiRating-iconFilled.neutral {
  color: orange;
}
.PleasureRating .MuiRating-iconFilled.satisfied {
  color: mediumseagreen;
}
.PleasureRating .MuiRating-iconFilled.very-satisfied {
  color: green;
}

.PleasureRating {
  padding: 5px 0;
}

.information-modal-line__col {
  display: inherit;
}

.information-modal-line__row {
  display: none;
}
@media (max-width: 520px) {
  .information-modal-line__col {
    display: none;
  }

  .information-modal-line__row {
    display: inherit;
  }
}

.add-meal__create-meal-button {
  position: absolute !important;
  bottom: 1em;
  right: 1em;
}

.add-meal__create-day-meals-button {
  position: absolute !important;
  bottom: 1em;
  right: 8em;
}

