@import "images/fruits.css";
@import "images/vegetables.css";
@import "images/meat.css";
@import "images/misc.css";

.icon-sm {
  width: 25px;
  height: 25px;
}

.icon-xs {
  width: 15px;
  height: 15px;
}

.icon-md {
  width: 35px;
  height: 35px;
}

.icon-lg {
  width: 45px;
  height: 45px;
}

.breakfast {
    background: transparent url('/images/breakfast.png') no-repeat center;
    background-size: cover;
}

.teatime {
    background: transparent url('/images/teatime.png') no-repeat center;
    background-size: cover;
}

.dairy {
    background: transparent url('/images/dairy.png') no-repeat center;
    background-size: cover;
}

.dinner {
    background: transparent url('/images/dinner.png') no-repeat center;
    background-size: cover;
}

.lunch {
    background: transparent url('/images/lunch.png') no-repeat center;
    background-size: cover;
}
