.apple {
  background: transparent url('/images/apple.png') no-repeat center;
  background-size: cover;
}

.apricot {
  background: transparent url('/images/apricot.png') no-repeat center;
  background-size: cover;
}

.banana {
  background: transparent url('/images/banana.png') no-repeat center;
  background-size: cover;
}

.blackberry {
  background: transparent url('/images/blackberry.png') no-repeat center;
  background-size: cover;
}

.blueberry {
  background: transparent url('/images/blueberry.png') no-repeat center;
  background-size: cover;
}

.brugnon {
  background: transparent url('/images/peach.png') no-repeat center;
  background-size: cover;
}

.cherry {
  background: transparent url('/images/cherry.png') no-repeat center;
  background-size: cover;
}

.citrus {
  background: transparent url('/images/citrus.png') no-repeat center;
  background-size: cover;
}

.clementine {
  background: transparent url('/images/clementine.png') no-repeat center;
  background-size: cover;
}

.coconut {
  background: transparent url('/images/coconut.png') no-repeat center;
  background-size: cover;
}

.fig {
  background: transparent url('/images/fig.png') no-repeat center;
  background-size: cover;
}

.gooseberry {
  background: transparent url('/images/gooseberry.png') no-repeat center;
  background-size: cover;
}

.guava {
  background: transparent url('/images/guava.png') no-repeat center;
  background-size: cover;
}

.grapefruit {
  background: transparent url('/images/grapefruit.png') no-repeat center;
  background-size: cover;
}

.grapes {
  background: transparent url('/images/grapes.png') no-repeat center;
  background-size: cover;
}

.kiwi {
  background: transparent url('/images/kiwi.png') no-repeat center;
  background-size: cover;
}

.lime {
  background: transparent url('/images/lime.png') no-repeat center;
  background-size: cover;
}

.mango {
  background: transparent url('/images/mango.png') no-repeat center;
  background-size: cover;
}

.melon {
  background: transparent url('/images/melon.png') no-repeat center;
  background-size: cover;
}

.mirabelle-plum {
  background: transparent url('/images/plum.png') no-repeat center;
  background-size: cover;
}

.nectarine {
  background: transparent url('/images/peach.png') no-repeat center;
  background-size: cover;
}

.orange {
  background: transparent url('/images/orange.png') no-repeat center;
  background-size: cover;
}

.passion-fruit {
  background: transparent url('/images/passion-fruit.png') no-repeat center;
  background-size: cover;
}

.peach {
  background: transparent url('/images/peach.png') no-repeat center;
  background-size: cover;
}

.pear {
  background: transparent url('/images/pear.png') no-repeat center;
  background-size: cover;
}

.persimmon {
  background: transparent url('/images/persimmon.png') no-repeat center;
  background-size: cover;
}

.pineapple {
  background: transparent url('/images/pineapple.png') no-repeat center;
  background-size: cover;
}

.plum {
  background: transparent url('/images/plum.png') no-repeat center;
  background-size: cover;
}

.prune {
  background: transparent url('/images/plum.png') no-repeat center;
  background-size: cover;
}

.quince {
  background: transparent url('/images/quince.png') no-repeat center;
  background-size: cover;
}

.raspberry {
  background: transparent url('/images/raspberry.png') no-repeat center;
  background-size: cover;
}

.rhubarb {
  background: transparent url('/images/rhubarb.png') no-repeat center;
  background-size: cover;
}

.strawberry {
  background: transparent url('/images/strawberry.png') no-repeat center;
  background-size: cover;
}

.tangerine {
  background: transparent url('/images/tangerine.png') no-repeat center;
  background-size: cover;
}

.watermelon {
  background: transparent url('/images/watermelon.png') no-repeat center;
  background-size: cover;
}
