.scrollable-container {
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  justify-content: center;
  outline: none;
}

.container {
  height: 100%;
  width: 98%;
  justify-content: center;
  margin: auto;
}
