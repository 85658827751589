.beef {
  background: transparent url('/images/beef.png') no-repeat center;
  background-size: cover;
}

.chicken {
  background: transparent url('/images/chicken.png') no-repeat center;
  background-size: cover;
}

.chicken-breast {
  background: transparent url('/images/chicken-breast.png') no-repeat center;
  background-size: cover;
}

.chopped-steak {
  background: transparent url('/images/chopped-steak.png') no-repeat center;
  background-size: cover;
}

.egg {
  background: transparent url('/images/egg.png') no-repeat center;
  background-size: cover;
}

.fish-food {
  background: transparent url('/images/fish-food.png') no-repeat center;
  background-size: cover;
}

.ham {
  background: transparent url('/images/ham.png') no-repeat center;
  background-size: cover;
}

.lamb {
  background: transparent url('/images/sheep.png') no-repeat center;
  background-size: cover;
}

.mutton {
  background: transparent url('/images/sheep.png') no-repeat center;
  background-size: cover;
}

.pig {
  background: transparent url('/images/pig.png') no-repeat center;
  background-size: cover;
}

.shrimp {
  background: transparent url('/images/shrimp.png') no-repeat center;
  background-size: cover;
}

.turkey {
  background: transparent url('/images/turkey.png') no-repeat center;
  background-size: cover;
}

.turkey-breast {
  background: transparent url('/images/chicken-breast.png') no-repeat center;
  background-size: cover;
}

.veal {
  background: transparent url('/images/beef.png') no-repeat center;
  background-size: cover;
}
