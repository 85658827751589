.artichoke {
  background: transparent url('/images/artichoke.png') no-repeat center;
  background-size: cover;
}

.asparagus {
  background: transparent url('/images/asparagus.png') no-repeat center;
  background-size: cover;
}

.avocado {
  background: transparent url('/images/avocado.png') no-repeat center;
  background-size: cover;
}

.beet {
  background: transparent url('/images/beet.png') no-repeat center;
  background-size: cover;
}

.bell-pepper {
  background: transparent url('/images/bell-pepper.png') no-repeat center;
  background-size: cover;
}

.broccoli {
  background: transparent url('/images/broccoli.png') no-repeat center;
  background-size: cover;
}

.brussels-sprout {
  background: transparent url('/images/brussels-sprout.png') no-repeat center;
  background-size: cover;
}

.cabbage {
  background: transparent url('/images/cabbage.png') no-repeat center;
  background-size: cover;
}

.carrot {
  background: transparent url('/images/carrot.png') no-repeat center;
  background-size: cover;
}

.cauliflower {
  background: transparent url('/images/cauliflower.png') no-repeat center;
  background-size: cover;
}

.celery {
  background: transparent url('/images/celery.png') no-repeat center;
  background-size: cover;
}

.chard {
  background: transparent url('/images/chard.png') no-repeat center;
  background-size: cover;
}

.corn {
  background: transparent url('/images/corn.png') no-repeat center;
  background-size: cover;
}

.coconut-milk {
  background: transparent url('/images/coconut-milk.png') no-repeat center;
  background-size: cover;
}

.cucumber {
  background: transparent url('/images/cucumber.png') no-repeat center;
  background-size: cover;
}

.eggplant {
  background: transparent url('/images/eggplant.png') no-repeat center;
  background-size: cover;
}

.fennel {
  background: transparent url('/images/fennel.png') no-repeat center;
  background-size: cover;
}

.garlic {
  background: transparent url('/images/garlic.png') no-repeat center;
  background-size: cover;
}

.gourd {
  background: transparent url('/images/gourd.png') no-repeat center;
  background-size: cover;
}

.leek {
  background: transparent url('/images/leek.png') no-repeat center;
  background-size: cover;
}

.mix-vegetable {
  background: transparent url('/images/mix-vegetable.png') no-repeat center;
  background-size: cover;
}

.mushroom {
  background: transparent url('/images/mushroom.png') no-repeat center;
  background-size: cover;
}

.olive {
  background: transparent url('/images/olive.png') no-repeat center;
  background-size: cover;
}

.onion {
  background: transparent url('/images/onion.png') no-repeat center;
  background-size: cover;
}

.parsnip {
  background: transparent url('/images/parsnip.png') no-repeat center;
  background-size: cover;
}

.peas {
  background: transparent url('/images/peas.png') no-repeat center;
  background-size: cover;
}

.potato {
  background: transparent url('/images/potato.png') no-repeat center;
  background-size: cover;
}

.pumpkin {
  background: transparent url('/images/pumpkin.png') no-repeat center;
  background-size: cover;
}

.radish {
  background: transparent url('/images/radish.png') no-repeat center;
  background-size: cover;
}

.soy {
  background: transparent url('/images/soy.png') no-repeat center;
  background-size: cover;
}

.spinach {
  background: transparent url('/images/spinach.png') no-repeat center;
  background-size: cover;
}

.squash {
  background: transparent url('/images/squash.png') no-repeat center;
  background-size: cover;
}

.sweet-potato {
  background: transparent url('/images/sweet-potato.png') no-repeat center;
  background-size: cover;
}

.tomato {
  background: transparent url('/images/tomato.png') no-repeat center;
  background-size: cover;
}

.turnip {
  background: transparent url('/images/turnip.png') no-repeat center;
  background-size: cover;
}

.white-beans {
  background: transparent url('/images/white-beans.png') no-repeat center;
  background-size: cover;
}

.zucchini {
  background: transparent url('/images/zucchini.png') no-repeat center;
  background-size: cover;
}
