:root {
  --color-font: #233140 !important;
  --color-background: white;
  --color-primary: #7ed739;
  --color-primary-dark: #71b323;
  --color-error: #a30200;
  --margin-xs: 0.25em;
  --margin-s: 0.5em;
  --margin-m: 1em;
  --margin-l: 1.5em;
}
